.profile-card-container {
    border-radius: 10%;
    border: 1px solid black;
    width: fit-content;
    padding: 1em;
    margin: 1em;
}

.profile-card-container:hover {
    cursor: pointer;
}

.profile-img-container {}

.profile-body-container {}