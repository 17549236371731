.searchBox {
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
}

.searchBox svg {
    font-size: 1.25rem;
    content: '';
    position: absolute;
    top: 23%;
    right: 26.5%;
    transform: translateY(55%);
}

.searchInput {
    border-radius: 50px;
    border: none;
    /* border: 1px solid black; */
    -webkit-box-shadow: inset 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: inset 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
    box-shadow: inset 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
    min-width: 50%;
    padding: 10px 20px;
}

.searchBar-resultContainer {
    height: 500px;
    z-index: 2;
    position: absolute;
    background: white;
    width: 50%;
    text-align: center;
    border-radius: 10px;
    /* border: 1px solid black; */
    left: 25%;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
}

.searchBar-result {
    padding: 15px;
    display: block;
}