.register-title {
    color: #A37B3B;
    font-family: Kamerik, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  

.register-subtitle{
  color: #000;
  font-family: Raleway, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
 line-height: normal;
}

.buyer-type-button{
  color: #A37B3B;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 32px;
  font-style: normal;
  line-height: normal;
  border: none;
  background: #FFFFFF;
  }
  
  .artist-type-button{
  color: #FFF;
  text-align: center;
  font-family:Roboto, sans-serif;   
  font-weight: 400;       
  font-size: 32px;
  font-style: normal;
  line-height: normal;
  display: flex;
  padding: 4px 35px;
  justify-content: center;
  align-items: center;
  gap: 40px;
  border: none;
  border-radius:10px;
  background: #CE9F4A;
  }


  .button-container {
    display: flex;
    width: 679px;
    justify-content: center;
    align-items: center;
    gap: 72px;
    }

    .form-login{
      display: inline-flex;
        padding:50px;
        flex-direction: column;
        align-items: center;
        gap: 15px;
    
    }


    .label-text{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 1px;
      color: #000;
      font-family: Raleway, sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      }


      .input-field{ 
        border-radius: var(--rounded, 10px);
        border: 1px solid #000;
        width: 556px;
        height: 59px;
        flex-shrink: 0;
          
        }

        .campos-field{
          display: flex;
            width: 682px;
            height: 301px;
            padding-left: 0px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1px;
          flex-shrink: 0;}

          
.campo-field-password{
	display: flex;
    width: 556px;
    height: 130px;
    padding: 10px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
    flex-shrink: 0;
}

.campo-field-usernameR{
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: flex-start;
     gap: 1px; 
     margin-top:400px;
}