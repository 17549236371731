.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  border: none;
  background-color: transparent;
  color: #333;
  font-size: 1.2rem;
  cursor: pointer;
}

.dropdown-button:hover {
  color: #666;
}

.dropdown-options {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.dropdown-option {
  display: block;
  padding: 8px;
  font-size: 1.2rem;
  color: #333;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.dropdown-option:hover {
  background-color: #f5f5f5;
}
