.form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-checkbox {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.form-errors {
    color: red;
}