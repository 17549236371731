/*@font-face {
  font-family: Roboto;
  src: url("Roboto-Thin.ttf") format(truetype);
}*/

@font-face {
  font-family: 'Kamerik';
  src: url('Kamerik105-Bold.ttf') format('truetype');
}

@import url('https://fonts.googleapis.com/css2?family=Raleway:family=Roboto:display=swap');


.form-login{
	display: inline-flex;
    padding:50px;
    flex-direction: column;
    align-items: center;
    gap: 15px;

}

.login-title {
  color: #A37B3B;
  font-family: Kamerik, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.login-subtitle {
   color: #000;
   font-family: Raleway, sans-serif;
   font-size: 32px;
   font-style: normal;
   font-weight: 400;
  line-height: normal;
}

.buyer-type-button{
color: #A37B3B;
text-align: center;
font-family: Roboto, sans-serif;
font-weight: 400;
font-size: 32px;
font-style: normal;
line-height: normal;
border: none;
background: #FFFFFF;
}

.artist-type-button{
color: #FFF;
text-align: center;
font-family:Roboto, sans-serif;   
font-weight: 400;       
font-size: 32px;
font-style: normal;
line-height: normal;
display: flex;
padding: 4px 35px;
justify-content: center;
align-items: center;
gap: 40px;
border: none;
border-radius:10px;
background: #CE9F4A;
}

.button-container {
display: flex;
width: 679px;
justify-content: center;
align-items: center;
gap: 72px;
}

.box{
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 1px;}

.input-field {
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 1px;
}

.input-field.error {
  border-color: red;
}

.label-text{
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 1px;
color: #000;
font-family: Raleway, sans-serif;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.input-field{ 
border-radius: var(--rounded, 10px);
border: 1px solid #000;
width: 556px;
height: 59px;
flex-shrink: 0;
	
}
.campos-field{
	}

.campo-field-password{
	display: flex;
    width: 556px;
    height: 130px;
    padding: 10px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
    flex-shrink: 0;
}

.campo-field-username{
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: flex-start;
     gap: 1.5px; 
    
}

.campo-field-alias{
	display: flex;
    width: 556px;
    height: 130px;
    padding: 10px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
    flex-shrink: 0;
}

.btn-login{
	display: flex;
    padding: 4px 52px;
    justify-content: center;
    align-items: center;
    gap: 40px;
    border: none;
    border-radius: 56px;
    background: #DC5B73;
    color: #FFF;
    text-align: center;
    font-family: Roboto, sans-serif;;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.btn-register{
    padding-top:20px;
	color: #B43A50;
    text-align: center;
    font-family: Roboto, sans-serif;;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
	line-height: normal;
    border: none;
    background: #FFFFFF;
}