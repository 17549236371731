nav {
    width: 100%;
    background-color: black;
}

.logo {
    line-height: 60px;
    float: left;
    margin: 0px 46px;
    color: #fff !important;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 2px;
}

nav .menu ul {
    line-height: 60px;
    list-style: none;
    background: rgba(0, 0, 0, 0);
    overflow: hidden;
    color: #fff !important;
    padding: 0;
    text-align: right;
    margin: 0;

    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.navbar-link {
    text-decoration: none;
    color: #fff !important;

    font-family: "Montserrat", Sans-serif;
    font-size: 11px;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 20.4px;
    letter-spacing: 2.2px;
}

nav .menu ul li {
    display: inline-block;
    box-sizing: border-box;
    text-align: center;
    margin: 0 5px;
}